import React from 'react'

const Schedule = () => {
    return (
        <div>
            schedule
        </div>
    )
}

export default Schedule
